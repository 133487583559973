import React from "react";
const FirmDetails = React.lazy(() => import("../components/clientFirms/firmDetails/FirmDetails"));
const ProxyStatusWrapper = React.lazy(() => import("../components/proxyStatus/ProxyStatusWrapper"));
const Protocols = React.lazy(() => import("../components/protocols/Protocols"));
const Tools = React.lazy(() => import("../components/tools/Tools"));
const Devices = React.lazy(() => import("../components/devices/Devices"));
const SimCards = React.lazy(() => import("../components/simCards/SimCards"));
const ClientFirms = React.lazy(() => import("../components/clientFirms/ClientFirms"));
const Vehicles = React.lazy(() => import("../components/vehicles/Vehicles"));
const UnAssociatedVehicles = React.lazy(() => import("../components/vehicles/UnAssociatedVehicles"));
const VehicleDevices = React.lazy(() => import("../components/vehicles/VehicleDevices"));
const ServerStatus = React.lazy(() => import("../components/serverStatus/ServerStatus"));
const Employee = React.lazy(() => import("../components/employee/Employee"));
const RawData = React.lazy(() => import("../components/rawData/RawData"));
const TeltonikaMetaData = React.lazy(() => import("../components/teltonikaMetaData/TeltonikaMetaData"));
const Invoices = React.lazy(() => import("../components/invoices/Invoices"));
const Accounts = React.lazy(() => import("../components/accounts/Accounts"));
const Trail = React.lazy(() => import("../components/trail/Trail"));
const Tariff = React.lazy(() => import("../components/tariffs/Tariff"));
const OfferConfigurator = React.lazy(() => import("../components/offerConfigurator/OfferConfigurator"));
const DeviceSettings = React.lazy(() => import("../components/carsData/DeviceSettings"));
const InvoiceGroups = React.lazy(() => import("../components/invoices/InvoiceGroups"));
const InvoiceDetails = React.lazy(() => import("../components/invoices/InvoiceDetails"));

export const persistentComponents = {
    Devices: [{component: <Devices />, path: "devices"}],
    SimCards: [{component: <SimCards />, path: "simCards"}],
    Vehicles: [{component: <Vehicles />, path: "vehicles"}],
    VehicleDevices: [{component: <VehicleDevices />, path: "vehicleDevices"}],
    ClientFirms: [{component: <ClientFirms />, path: "clientFirms"}],
}

export const normalComponents = {
    ServerStatus : [{component: <ServerStatus />, path: "serverStatus"}],
    Employee: [{component: <Employee />, path: "employee"}],
    GpsProgramRawData: [{component: <RawData />, path: "rawData"}],
    Invoice: [{component: <Invoices />, path: "invoices"}],
    Users: [{component: <Accounts />, path: "accounts"}],
    GpsProgramRawDataTrail: [{component: <Trail />, path: "trail"}],
    Tariffs: [{component: <Tariff />, path: "tariffs"}],
    OfferConfigurator: [{component: <OfferConfigurator />, path: "offerConfigurator"}],
    GpsProgramGpsCommand: [{component: <DeviceSettings />, path: "deviceSettings"}],
    InvoiceGroups: [{component: <InvoiceGroups />, path: "invoiceGroups"}],
    InvoiceDetails: [{component: <InvoiceDetails />, path: "invoiceDetails"}],
    UnAssociatedVehicles: [{component: <UnAssociatedVehicles />, path: "unAssociatedVehicles"}],
    Tools: [{component: <Tools/>, path: "tools"}],
    GpsProgramMetaData: [{component: <TeltonikaMetaData/>, path: "teltonikaMetaData"}],
    Protocols: [{component: <Protocols/>, path: "protocols"}],
    ClientFirms: [{component: <FirmDetails />, path: "clientFirms/details/:clientFirmId"}],
    GpsProgramTcpProxyStats: [{component: <ProxyStatusWrapper />, path: "proxyStatus"}],
}
