import {useContext, useEffect, useRef} from 'react';
import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {apiUrl} from './config/Config'
import {UserContext} from "./context/UserContext";
import {ToastContext} from './context/ToastContext';
import {useIntl} from 'react-intl';
import {usePreloader} from "./hooks/usePreloader";
import {AuthService} from "../services/authService";

export const AxiosInterceptor = (props: any) => {
    const {apiMode} = useContext(UserContext);
    const toast = useContext(ToastContext);
    const {formatMessage: f} = useIntl();
    const {setLoading} = usePreloader();
    const authService = new AuthService();

    const configRef = useRef<any>(null);
    const apiRef = useRef<string>(apiUrl.prod);

    //!IMPORTANT This function should stay in the useEffect so onComponentDidUnmount the interceptor is being ejected and the replaced with new one!
    useEffect(() => {
        const authInterceptor = axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            const isSWRRequest = config.data && config.data.isSWR;
            if (config.headers && !config.headers.skipPreloader && !isSWRRequest) {
                setLoading(true);
                delete config.headers.skipPreloader
            }

            config.baseURL = apiRef.current;
            configRef.current = config;
            // console.log("the config is:", config);
            config.withCredentials = true;
            // if (config.responseType === "arraybuffer") toast.showToast("info", <div className={'p-d-inline-flex align-items-center'}><ProgressSpinner style={{ width: '30px', height: '30px', marginRight: '1rem' }} strokeWidth="1" animationDuration="1s" />  {f({ id: "fileDownloading" })}</div>);
            return config
        });

        props.callback();

        return () => {
            axios.interceptors.request.eject(authInterceptor);
        }
    }, []);

    useEffect(() => {
        apiRef.current = apiMode === "dev" ? apiUrl.dev : apiMode === "dev2" ? apiUrl.dev2 : apiUrl.prod;
    }, [apiMode])

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use((response: AxiosResponse<any>) => {
            setLoading(false);
            if (response.data.message) {
                toast.showToast("success", f({id: "succoperation"}), f({id: response.data.message}));
            } else if (response.data.indexOf !== undefined && response.data.indexOf('ok') === 0 && response.data.indexOf('message') !== -1) {
                const data = JSON.parse(response.data.substr(response.data.indexOf('{'), response.data.length));
                toast.showToast("success", f({id: "succoperation"}), f({id: data.message}));
            }
            return response;
        }, (err) => {
            setLoading(false);
            if (err.response && err.response.status) {
                const priority = err.response.status >= 400 && err.response.status <= 500 ? "warn" : "error";
                // const message = err.response.status >= 400 && err.response.status < 500 ? err.response.data.message : "internalServerError";
                // const sticky = err.response.data.sticky;
                if (err.response.status === 401 && err.response.config.url !== "users/checkIfAuthenticated") {
                    authService.logout().then(() => {
                        window.history.go(-1);
                        window.location.reload();
                    });
                } else if (err.response.status === 403) {
                    //TODO: When permissions are properly adjusted show the toast again
                    toast.showToast(priority, f({id: "operationFailed"}), f({id: 'noAccessToThisResource'}), false);
                } else if (err.response.status === 404)
                    toast.showToast(priority, f({id: "operationFailed"}), f({id: 'resourceNotFound'}), false);
                else if (err.response.status === 409)
                    toast.showToast(priority, f({id: "operationFailed"}), f({id: 'suchRecordAlreadyExist'}), false);
                else if (err.response.status === 400 && err.response.data && err.response.data.errors) {
                    const body = <ul>
                        {Object.values(err.response.data.errors).map(el => {
                            return <li>{el as string}</li>
                        })}
                    </ul>
                    toast.showStickyToast(priority, f({id: "operationFailed"}), body, true);
                } else if (err.response.status === 400 && err.response.data) {
                    toast.showStickyToast(priority, f({id: "operationFailed"}), err.response.data, true);
                } else if (err.response.status >= 500) {
                    toast.showToast(priority, "AI MAIKO");
                } else if (err.response.config.url !== "users/checkIfAuthenticated")
                    toast.showToast(priority, f({id: "operationFailed"}), f({id: 'exceptionOccurred'}), false);
            } else if ((err.config.baseURL === apiUrl.dev || err.config.baseURL === apiUrl.dev2) && (err.response && err.response.config.url !== "users/checkIfAuthenticated")) {
                try {
                    localStorage.setItem('env', "prod");
                } catch (e) {
                    console.error(e);
                }
                apiRef.current = apiUrl.prod;
                toast.showToast("warn", f({id: "exceptionOccurred"}), f({id: "switchedBackToProd"}));
            } else {
                toast.showToast("error", f({id: "internalServerError"}));
            }
            return Promise.reject(err);
        });

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        }
    }, []);

    return <>
        {props.children}
    </>
};
